<template>
  <div class="home">
    <BlogPost v-if="!user" :post="welcomeScreen" />
    <BlogPost v-for="(post, index) in blogPostsFeed" :key="index" :post="post" />
    <section class="recent-posts">
      <div class="container">
        <h2>Publicaciones más recientes</h2>
        <div class="cards-container">
          <BlogCard :post="post" v-for="(post, index) in blogPostsCards" :key="index" />
        </div>
      </div>
    </section>
    <section class="subscribe-reminder">
      <div class="container">
        <div class="subscribe-title">
          <h2>No te pierdas nunca una publicación!</h2>
          <h2>Suscríbete para recibir lo último en tu email</h2>
        </div>
        <form action="post" @submit.prevent="handleSubmit">
          <input type="email" required placeholder="Inserta tu e-mail">
          <input type="submit" value="Subscribirse!">
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import BlogPost from "@/components/BlogPost.vue"
import BlogCard from "@/components/BlogCard.vue"
export default {
  name: "Home",
  components: { BlogPost, BlogCard },
  data() {
    return {
      welcomeScreen: {
        title: "Bienvenido a Cinecorder!",
        postContent: "We upload weekly blog articles about all the most interesting aspects of filmmaking and cinema.",
        welcomeScreen: true,
        photo: "portada-blog"
      },

    }
  },
  computed: {
    blogPostsFeed() {
      return this.$store.getters.blogPostsFeed
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards
    },
    user() {
        return this.$store.state.user
    }
  },
};
</script>

<style lang="sass" scoped>
@use "../style/fonts"
@use "../style/colors"
@use "../style/__blogCardsSection"

section.subscribe-reminder
  height: 250px
  background: colors.$red
  color: colors.$white
  .container
    height: 100%
    width: 95%
    display: flex
    gap: 10px
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center

    @media (max-width: 1024px)
      flex-direction: column
      justify-content: center
      h2
        text-align: center
      form
        width: clamp(310px, 75vw, 700px)
      input[type="email"]
        width: 100%

  .subscribe-title h2
    font-weight: 200
    text-transform: uppercase
    font-size: fonts.$u2
    
  form
    display: flex
    flex-flow: row nowrap
    position: relative
    input
      font-size: fonts.$u0
      padding: 5px 10px
      height: 50px
      border: 0
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
      &:focus-visible
        outline: 0
    input[type="email"]
      width: 500px
      border-radius: 20px
      padding-left: 15px 
    input[type="submit"]
      position: absolute
      right: -2px
      background: colors.$black
      color: colors.$white
      border-radius: 20px
      width: 140px
      font-weight: 600
      cursor: pointer
      transition: all ease-in-out 0.2s
      &:hover
        width: 150px
        font-weight: 700

</style>
