<template>
    <section class="post-view">
        <p @click="back" class="go-back link link-dark hover-underline">&lt; Volver atrás</p>
        <div class="container quillWrapper">
            <img class="cover-photo" :src="blogCoverPhoto" :alt="blogTitle">
            <h1 class="post-title">{{ this.blogTitle }}</h1>
            <div class="post-content ql-editor" v-html="blogHTML">

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "BlogPreview",
    computed: {
        blogTitle() {
            return this.$store.state.blogTitle
        },
        blogCoverPhoto() {
            return this.$store.state.blogPhotoFileURL
        },
        blogHTML() {
            return this.$store.state.blogHTML
        },
        blogTag() {
            return this.$store.state.blogTag
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style lang="sass">
@use "../style/fonts"
@use "../style/colors"
section.post-view
    max-width: 1100px
    width: 95%
    margin: 20px auto 80px
    .cover-photo
        object-fit: cover
    .ql-video
        width: clamp(50%, 1280px, 90%)
        aspect-ratio: 16 / 9
    a
        text-decoration: underline
    .cover-photo
        width: 100vw
        background: colors.$light-gray
        height: clamp(50vh, 500px, 80vh)
        object-fit: cover
        object-position: center
    .post-title
        font-size: fonts.$u3
        margin-bottom: 20px
    h1
        font-size: fonts.$u3
    h2
        font-size: fonts.$u2
    .post-tags
        display: inline-block
        margin-right: 10px
        font-size: fonts.$u1
        color: colors.$red
        text-transform: uppercase
        margin-top: 10px
    .ql-editor
        font-weight: 400
        padding: 0
        line-height: 1.8
        font-size: fonts.$u0
    .go-back
        display: inline-block
@media (min-width: 768px)
    section.post-view
        margin: 50px auto 80px
        .go-back
            margin-block: 10px
</style>