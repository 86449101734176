<template>
  <article>
    <img loading="lazy" v-if="post.welcomeScreen" :src="require(`@/assets/blogPhotos/${post.photo}.jpg`)" :alt="post.title">
    <img loading="lazy" v-else :src="post.blogCoverPhoto" :alt="post.blogTitle">
    <div class="blog-info">
        <div class="info-container container">
        <h1 v-if="post.welcomeScreen">{{ post.title }}</h1>
        <h2 v-else>{{ post.blogTitle }}</h2>

        <p v-if="post.welcomeScreen">{{ post.blogPost }}</p>
        <p class="body-preview" v-else>{{ post.blogDescription }}</p>

        <router-link class="link link-light hover-underline" v-if="post.welcomeScreen" :to="{ name: 'Login' }">Inicia sesión o Regístrate <Arrow class="arrow arrow-light" /></router-link>
        <router-link class="link hover-underline" v-else :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID, title: this.post.blogTitle }}">Leer más <Arrow class="arrow arrow-dark" /></router-link>
        </div>
    </div>
  </article>
</template>

<script>
import Arrow from "@/assets/Icons/arrow-right-light.svg"
export default {
    components: {
        Arrow
    },
    name: "blogPost",
    props: ["post"],
}
</script>

<style lang="sass" scoped>
@use "../style/colors"
@use "../style/fonts"
article
    width: 100%
    height: 550px
    display: flex
    background: colors.$white
    color: colors.$black
    &:nth-child(1)
        background: colors.$black
        color: colors.$white
        .link
            border-bottom: 2px solid colors.$black
        .link:hover
            border-bottom: 2px solid colors.$white
    &:nth-child(2n+1)
        flex-direction: row-reverse
        &:not(:first-child)
            .link
                border-bottom: 2px solid colors.$white
            .link:hover
                border-bottom: 2px solid colors.$black
    &:nth-child(2n)
        flex-direction: row
        .link
            border-bottom: 2px solid colors.$white
        .link:hover
            border-bottom: 2px solid colors.$black
    
    .blog-info
        width: 40%
        display: flex
        flex-flow: column nowrap
        justify-content: center
        .info-container
            width: 70%
        h2
            font-size: fonts.$u2
            font-weight: 400
            margin-bottom: 15px
        .link
            font-size: fonts.$u0
        p
            margin-bottom: 30px
    img
        width: 60%
        height: 100%
        object-fit: cover
        background: colors.$light-gray
    @media (max-width: 768px)
        .blog-info, img
            width: 50%
@media (max-width: 560px)
    article
        &:nth-child(2n+1),
        &:nth-child(2n)
            flex-direction: column
            height: auto
        img
            width: 100%
            height: 300px
        .blog-info
            width: 100%
            height: auto
            margin: 20px auto
.arrow
    width: 10px
</style>