<template>
  <div class="modal">
      <div class="modal-container">
          <p>{{ this.modalMessage }}</p>
          <button class="btn" @click="closeModal">Cerrar</button>
      </div>
  </div>
</template>

<script>
export default {
    props: ["modalMessage"],
    methods: {
        closeModal() {
            this.$emit("close-modal")
        }
    },
}
</script>

<style lang="sass" scoped>
@use "../style/colors"
@use "../style/fonts"

.modal
    top: 0
    position: absolute
    width: 100%
    height: 100%
    background: rgba(23, 33, 38, 0.8)
    z-index: 10
    display: flex
    align-items: center
    justify-content: center
    .modal-container
        background: colors.$white
        width: 320px
        display: flex
        justify-content: space-between
        flex-flow: column nowrap
        padding: 30px 30px
        border-radius: 30px
    p
        font-size: fonts.$u0
    .btn
        width: 50%
        align-self: flex-end
        margin-top: 10px
        
</style>