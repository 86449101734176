<template>
  <div class="modal" ref="modal">
      <div class="modal-container">
          <Close @click="closePreview" class="icon"/>
          <img :src="this.blogCoverPhoto" :alt="this.blogCoverPhoto">
      </div>
  </div>
</template>

<script>
import Close from "@/assets/Icons/times-circle-light.svg"
export default {
    components: { Close },
    methods: {
        closePreview() {
            this.$store.commit("openPhotoPreview")
        }
    },
    computed: {
        blogCoverPhoto() {
            return this.$store.state.blogPhotoFileURL
        }
    }
}
</script>

<style lang="sass" scoped>
@use "../style/colors"
.modal
    top: 0
    position: absolute
    width: 100%
    height: 100%
    background: rgba(23, 33, 38, 0.8)
    z-index: 10
    display: flex
    align-items: center
    justify-content: center
    .modal-container
        background: colors.$white
        width: 600px
        min-height: 300px
        display: flex
        justify-content: space-between
        flex-flow: column nowrap
        padding: 30px 30px
        border-radius: 30px
        position: relative
        img
            width: 550px
            height: auto
        .icon
            position: absolute
            top: 10px
            right: 10px
            cursor: pointer
            width: 50px
            background: colors.$white
            border-radius: 50px
            padding: 7px
            
</style>