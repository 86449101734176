<template>
  <main>
    <Loader v-if="isLoading" />
    <PostCoverPreview  v-show="this.$store.state.blogPhotoPreview" />
    <section class="container container__create-post">

      <div class="post-info">
        <input class="blog-title" type="text" placeholder="Pon un título a tu post..." v-model="blogTitle">
        <input class="cover-photo" type="file" ref="blogPhoto" id="cover-photo" accept=".png, .jpg, .jpeg, .webp" @change="fileChange"/>
        <button class="btn btn-preview" :class="{ 'btn-disabled': !this.$store.state.blogPhotoFileURL }" @click="togglePreviewCover">Previsualizar Portada</button>
        <span>Archivo elegido: {{ this.$store.state.blogPhotoName }}</span>
      </div>

      <div class="editor">
        <vue-editor
          :editorOptions="editorSettings"
          v-model="blogHTML"
          useCustomImageHandler
          @image-added="imageHandler"
        />
      </div>
      <div class="tag-section">
        <label for="tag">Etiqueta de post</label>
        <input type="text" v-model="textTag">
        <p class="tag-characters" :class="{ error: textTag.length === 0 || textTag.length > 30 }">{{ textTag.length }}/30 Caracteres</p>
      </div>
      <div class="description-section">
        <label for="description">Descripción de post</label>
        <textarea name="description-input" cols="30" rows="3" v-model="textDescription" placeholder="Pon una descripción a tu post..."></textarea>
        <p class="description-characters" :class="{ error: textDescription.length === 0 || textDescription.length > 120 }">{{ textDescription.length }}/120 Caracteres</p>
      </div>
      <div :class="{invisible: !error}" class="err-message error">
        <p>Error: {{ this.errorMsg }}</p>
      </div>

      <div class="post-options">
        <button class="btn" @click="uploadBlog">Publicar Post</button>
        <router-link class="btn router-btn" :to="{ name: 'BlogPreview' }">Previsualizar Post</router-link>
      </div>


    </section>
  </main>
</template>

<script>
import Loader from "@/components/Loader.vue"
import firebase from "firebase/app"
import "firebase/storage"
import db from "@/firebase/firebaseInit"
import PostCoverPreview from "@/components/PostCoverPreview"
import Quill from "quill"

window.Quill = Quill
const ImageResize = require("quill-image-resize-module").default
Quill.register("modules/imageResize", ImageResize)


export default {
  components: {
    PostCoverPreview, Loader
  },
    name: "CreatePost",
    data() {
      return {
        error: null,
        errorMsg: "",
        file: null,
        previewCover: null,
        isLoading: null,
        descriptionError: null,
        textDescription: "",
        textTag: "",
        editorSettings: {
          modules: {
            imageResize: {},
          }
        }
      }
    },
    computed: {
      profileId() {
        return this.$store.state.profileId
      },
      blogCoverPhotoName() {
        return this.$store.state.blogPhotoName;
      },
      blogTitle: {
        get() {
          return this.$store.state.blogTitle;
        },
        set(payload) {
          this.$store.commit("updateBlogTitle", payload);
        },
      },
      blogHTML: {
        get() {
          return this.$store.state.blogHTML;
        },
        set(payload) {
          this.$store.commit("newBlogPost", payload);
        },
      },
      blogDescription: {
        get() {
          return this.$store.state.blogDescription;
        },
        set(payload) {
          this.$store.commit("updateBlogDescription", payload)
        }
      },
      blogTag: {
        get() {
          return this.$store.state.blogTag;
        },
        set(payload) {
          this.$store.commit("updateBlogTag", payload)
        }
      },
    },
    methods: {
      fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit("fileNameChange", fileName);
      this.$store.commit("createFileURL", URL.createObjectURL(this.file));
      },
      togglePreviewCover() {
        this.$store.commit("openPhotoPreview")
      },
      imageHandler(file, Editor, cursorLocation, resetUploader) {
        const storageRef = firebase.storage().ref()
        const docRef = storageRef.child(`documents/blogPostPhotos/${ file.name }`)
        docRef.put(file).on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot)
          },
          (err) => {
            console.log(err)
          }, async () => {
            const downloadURL = await docRef.getDownloadURL()
            Editor.insertEmbed(cursorLocation, "image", downloadURL)
            resetUploader()
          }
        )
      },
      uploadBlog() {
        if(this.blogTitle.length !== 0 && this.blogHTML !== "") {
          if(this.file) {
            if(this.textDescription.length !== 0 && this.textDescription.length <= 120 && this.textTag.length !== 0 && this.textTag.length <= 30) {
              this.isLoading = true
              const storageRef = firebase.storage().ref()
              const docRef = storageRef.child(`documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`)
              docRef.put(this.file).on(
                "state_changed",
                (snapshot) => {
                  console.log(snapshot)
                }, (err) => {
                  console.log(err)
                }, async () => {
                  const downloadURL = await docRef.getDownloadURL()
                  const timestamp = await Date.now();
                  const dataBase = await db.collection("blogPosts").doc()

                  await dataBase.set({
                    blogID: dataBase.id,
                    blogHTML: this.blogHTML,
                    blogCoverPhoto: downloadURL,
                    blogCoverPhotoName: this.blogCoverPhotoName,
                    blogTitle: this.blogTitle,
                    blogAuthor: this.profileId,
                    blogDescription: this.textDescription,
                    blogTag: this.textTag,
                    date: timestamp,
                  })
                await this.$store.dispatch("getPost")
                this.isLoading = false
                this.$router.push({ name: "ViewBlog", params: { blogid: dataBase.id } })
                }
              )
              return
            } else {
              this.error = true
              this.errorMsg = "Comprueba de llenar correctamente los campos de texto de descripción y etiqueta"
              setTimeout(() => {
                this.error = false
              }, 10000)
            }
          } else {
            this.error = true
            this.errorMsg = "Por favor, sube una foto de portada para el post del blog."
            setTimeout(() => {
              this.error = false
            }, 10000)
          }
        } else {
          this.error = true
          this.errorMsg = "Por favor, asegúrate de rellenar el título y el contenido del cuerpo."
          setTimeout(() => {
            this.error = false
          }, 10000)
        }
      }
    },
}
</script>

<style lang="sass">
@use "../style/colors"
@use "../style/fonts"
.container__create-post
  margin-inline: auto
  width: 95%
  .invisible
    opacity: 0

  .post-info
    display: flex
    flex-flow: row wrap
    margin-block: 10px
    gap: 15px
    align-items: center
    @media (max-width: 710px)
        justify-content: center
    .blog-title
      width: 400px
      font-size: fonts.$u-1
      font-weight: 500
      outline: none
      border: none
      border-bottom: 3px solid colors.$light-gray
      border-radius: 2px
      padding: 10px 10px
      transition: 0.2s ease-in-out
      &:focus-visible
        border-color: colors.$black
      @media (max-width: 710px)
        width: 100%
    input.cover-photo
      visibility: hidden
      width: 243px
      &::before
        visibility: visible
        content: "Sube una foto de portada para el post. (Máximo 5MB)"
        display: inline-block
        border: 0
        background: colors.$black
        color: colors.$white
        font-weight: 300
        font-size: fonts.$u0
        padding: 7px 17px
        border-radius: 20px
        cursor: pointer
        transition: background ease-in-out 0.3s
        z-index: 2
        &:hover
          background: colors.$gray
    .btn
      height: 40px

  .editor
    height: 80vh
    display: flex
    flex-direction: column
    .quillWrapper
      height: 100%
      position: relative
      display: flex
      flex-direction: column
    .ql-container
      display: flex
      flex-direction: column
      height: 100%
      overflow-y: scroll
      overflow-x: hidden
    .ql-editor
      padding: 20px 16px 30px
      width: 100%

  .description-section,
  .tag-section
    margin-block: 20px
    font-size: fonts.$u-1
    textarea,
    input
      margin-block: 10px
      display: block
      width: 100%
      font-size: fonts.$u0
      padding: 5px 10px
      border: 1px solid colors.$black
      border-radius: 4px
    .description-characters,
    .tag-characters
      font-size: fonts.$u-1
  .post-options
    margin-block: 35px
    .btn:first-child
      margin-right: 15px
.err-message
  margin-top: 10px
  font-size: fonts.$u-1
</style>