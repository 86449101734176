<template>
  <div class="background">
    <div class="form-container">
      <p @click="back" class="go-back link link-dark link-underline">&lt; Ir atrás</p>
      <form action="post" @submit.prevent="signIn">
        <router-link class="link link-dark hover-underline" :to="{ name: 'Register' }">No tienes una cuenta? Regístrate!</router-link>
        <h1>Inicia sesión en Cinecorder</h1>
        <div class="input">
          <EmailIcon class="input-icon"/>
          <input type="email" required placeholder="E-Mail" v-model="email">
        </div>
        <div class="input">
          <PasswordIcon class="input-icon"/>
          <input type="password" required placeholder="Contraseña" v-model="password">
        </div>
        <router-link class="link link-dark hover-underline forgot-link" :to="{ name: 'ChangePassword' }">¿Has olvidado tu contraseña?</router-link>
        <p class="error error-msg">{{ this.errorMsg }}</p>
        <input class="btn" type="submit" value="Iniciar sesión">
      </form>
    </div>
    <UserIllustration class="user-illustration"/>
  </div>
</template>

<script>
import EmailIcon from "@/assets/Icons/envelope-regular.svg"
import PasswordIcon from "@/assets/Icons/lock-alt-solid.svg"
import UserIllustration from "@/assets/user-illustration.svg"
import firebase from "firebase/app"
import "firebase/auth"

export default {
  name: "Login",
  components: { UserIllustration, EmailIcon, PasswordIcon },
  data() {
    return {
      email: "",
      password: "",
      error: null,
      errorMsg: ""
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    signIn() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
        this.$router.push({ name: "Home" })
        this.error = false
        this.errorMsg = ""
        console.log(firebase.auth().currentUser.uid)
      }).catch(err => {
        this.error = true
        this.errorMsg = err.message
      })
    }
  },
  
}
</script>

<style lang="sass" scoped>
@use "../style/_userRegistration"
@use "../style/colors"
@use "../style/fonts"
h1
  font-size: fonts.$u3
  margin-block: 20px
  text-align: center
input::placeholder
      color: colors.$gray
input[type="email"],
input[type="password"]
  width: 100%
  height: 40px
  padding-left: 35px
  font-size: fonts.$u-1
  background: colors.$white-shadow
  border: 0
  border-radius: 5px
  outline: 2px auto colors.$white-fff
  transition: outline ease-in-out 0.2s
  &:focus-visible
    outline: 2px auto colors.$gray
</style>