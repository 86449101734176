<template>
  <footer>
      <div class="container footer-container">
          <div class="footer-section">
            <h4 class="footer__title">Cinecorder</h4>
            <div class="footer-social__links">
                <a href="https://www.instagram.com/tomy.simonetti/" target="__blank"><instagramIcon class="social__link link"/></a>
                <a href="https://www.linkedin.com/in/tomas-simonetti/" target="__blank"><linkedinIcon class="social__link link"/></a>
                <a href=""><youtubeIcon class="social__link link"/></a>
                <a href=""><twitterIcon class="social__link link"/></a>
            </div>
          </div>

          <div class="footer-section">
              <router-link :to="{ name: 'Home' }" class="link">Inicio</router-link>
              <router-link :to="{ name: 'Posts'}" class="link">Blog</router-link>
              <a class="link" href="https://simonettitomas.com/" target="__blank">Mi Portafolio</a>
          </div>
          <div class="footer-section">
              <p class="footer__text">Copyright © Simonetti Tomás 2022</p>
              <p class="footer__text">All rights reserved</p>
              
          </div>
      </div>
  </footer>
</template>

<script>
import instagramIcon from "@/assets/Icons/instagram-brands.svg"
import linkedinIcon from "@/assets/Icons/linkedin-brands.svg"
import twitterIcon from "@/assets/Icons/twitter-brands.svg"
import youtubeIcon from "@/assets/Icons/youtube-brands.svg"

export default {
    components: { instagramIcon, linkedinIcon, twitterIcon, youtubeIcon },
    computed: {
        user() {
            return this.$store.state.user
        },
        admin() {
            return this.$store.state.profileAdmin
        }
    }
}
</script>

<style lang="sass" scoped>
@use "../style/fonts"
@use "../style/colors"

footer
    width: 100%
    height: 250px
    background: colors.$black
    color: colors.$white
    padding: 30px 0
    @media (max-width: 768px)
        height: 300px
    
.footer-container
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    width: 95%
    height: 100%
    margin-block: center
    .footer-section
        display: flex
        flex-flow: column nowrap
    @media (max-width: 768px)
        flex-direction: column
        text-align: center
        gap: 5px
        .footer-section:nth-child(2)
            flex-flow: row wrap
            gap: 15px
            justify-content: center
            .footer__link
                margin-inline: 10px
.footer-social__links
    display: flex
    flex-flow: row nowrap
    justify-content: center


// LINKS AND TEXT FORMATTING

.footer__title
    font-size: fonts.$u2
    color: colors.$red
.footer__text
    font-size: fonts.$u-1
.link,
.social__link
    font-size: fonts.$u0
    margin-block: 5px
.link,
.social__link
    &:hover
        color: colors.$red
.social__link
    height: 25px
    margin-inline: 10px

// MOBILE

@media (max-width: 768px)


</style>