<template>
  <header>
      <nav class="container">
          <div class="site-logo">
              <router-link class="site-logo__title" :to="{ name: 'Home' }">Cinecoder</router-link>
          </div>
              <ul v-show="!mobile" class="nav-links">
                  <router-link class="nav-links__link" :to="{ name: 'Home' }" >Inicio</router-link>
                  <router-link class="nav-links__link" :to="{ name: 'Posts'}" >Blog</router-link>
                  <a class="nav-links__link" href="https://simonettitomas.com/" target="__blank">Mi Portafolio</a>
                  <router-link v-if="admin" class="nav-links__link" :to="{ name: 'CreatePost'}" >Crear post</router-link>
                  <router-link v-if="!user" class="nav-links__link" :to="{ name: 'Login'}" >Login/Registrarse</router-link>

                  <div v-if="user" class="profile-container" ref="profile">
                      <div class="profile-initials-container" @click="toggleProfileMenu">
                          <span class="profile-initials">{{ this.$store.state.profileInitials }}</span>
                      </div>
                      <div class="profile-menu" v-show="profileMenu">
                        <div class="profmenu__details">
                            <div class="profmenu__initials-container profile-initials-container">
                                <p class="profmenu__profile-initials profile-initials">{{ this.$store.state.profileInitials }}</p>
                            </div>
                            <div class="profmenu__data-container">
                                <p>{{ this.$store.state.profileName }}</p>
                                <p>@{{ this.$store.state.profileUsername }}</p>
                                <p>{{ this.$store.state.profileEmail }}</p>
                            </div>
                        </div>
                        <ul>
                          <li>
                            <router-link class="link link-light" :to="{ name: 'Profile' }" >
                                <ProfileIcon class="profmenu__icon"/>
                                <span>Perfil</span>
                            </router-link>
                          </li>
                          <li v-if="admin">
                            <router-link class="link link-light" :to="{ name: 'Admin' }" >
                                <AdminIcon class="profmenu__icon"/>
                                <span>Admin</span>
                            </router-link>
                          </li>
                          <li @click="signOut" class="link link-light">
                            <ExitIcon class="profmenu__icon"/>
                            <span>Desconectarse</span>
                          </li>
                        </ul>
                      </div>
                  </div>

              </ul>
      </nav>
      <navIcon class="burguer-nav" @click="toggleMobileNav" v-show="mobile"/>
      <transition name="mobile-nav">
        <ul class="mobile-nav" v-show="mobileNav">
            <router-link class="nav-links__link" :to="{ name: 'Home' }">Inicio</router-link>
            <router-link class="nav-links__link" :to="{ name: 'Posts'}">Blog</router-link>
            <a class="nav-links__link" href="https://simonettitomas.com/" target="__blank">Mi Portafolio</a>
            <router-link v-if="admin" class="nav-links__link" :to="{ name: 'CreatePost'}">Crear Post</router-link>
            <router-link v-if="!user" class="nav-links__link" :to="{ name: 'Login'}">Login/Registrarse</router-link>
        </ul>
      </transition>
      
  </header>
</template>

<script>
import ProfileIcon from "@/assets/Icons/user-alt-light.svg"
import AdminIcon from "@/assets/Icons/user-crown-light.svg"
import ExitIcon from "@/assets/Icons/sign-out-alt-regular.svg"
import navIcon from "@/assets/Icons/bars-regular.svg"
import firebase from "firebase/app"
import "firebase/auth"
export default {
    name: "Navigation",
    components: {
        navIcon, ProfileIcon, AdminIcon, ExitIcon
    },
    data() {
        return {
            mobile: null,
            mobileNav: null,
            windowWidth: null,
            profileMenu: false,
        }
    },
    created() {
        window.addEventListener('resize', this.checkScreen)
        this.checkScreen()
    },
    methods: {
        checkScreen() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth <= 750) {
                this.mobile = true
                return
            } else {
                this.mobile = false
                this.mobileNav = false
            }
        },
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav
        },
        toggleProfileMenu() {
            this.profileMenu = !this.profileMenu
        },
        signOut() {
            firebase.auth().signOut()
            window.location.reload()
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        admin() {
            return this.$store.state.profileAdmin
        }
    },
}
</script>

<style lang="sass" scoped>
@use "../style/fonts"
@use "../style/colors"

// GENERAL 
header
    width: 100%
    background: colors.$white
    height: 100px
    color: colors.$black
    z-index: 2
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)
    .nav-links
        display: flex
        flex-flow: row nowrap
        align-items: center
.nav-links__link
    margin-inline: 10px
    font-weight: 500
    font-size: fonts.$u0
    transition: ease-in-out 0.2s
    &:last-child
        margin-right: 0
    &:hover
        color: colors.$red
// DESKTOP NAV
nav.container
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    align-items: center
    width: 95%
    height: 100%
    .site-logo__title
        font-weight: 700
        font-size: fonts.$u2
        color: colors.$red

// MOBILE NAV

.burguer-nav
    height: 35px
    right: 30px
    position: absolute
    top: 32.5px
    cursor: pointer
.mobile-nav
    background: colors.$black
    color: colors.$white
    display: flex
    flex-flow: column nowrap
    width: 250px
    max-width: 75vw
    height: 100%
    position: absolute
    top: 0
    padding: 50px 10px
    text-transform: uppercase
    z-index: 5
    .nav-links__link
        margin-bottom: 20px

// MOBILE NAV TRANSITION
.mobile-nav-enter-active,
.mobile-nav-leave-active
    transition: 1s ease-in-out
.mobile-nav-enter
    transform: translateX(-250px)
.mobile-nav-enter-to
    transform: translateX(0)
.mobile-nav-leave-to
    transform: translateX(-250px)
.profile-container
    position: relative

// PROFILE INITIALS
.profile-initials-container
    display: flex
    justify-content: center
    align-items: center
    background: colors.$black
    width: 40px
    height: 40px
    border-radius: 50px
    cursor: pointer
    .profile-initials
        text-transform: uppercase
        font-size: fonts.$u0
        font-weight: 600
        color: colors.$white

// PROFILE MENU
.profile-menu
    position: absolute
    top: 65px
    right: -20px
    background: colors.$black
    color: colors.$white
    width: 280px
    border-radius: 5px
    z-index: 10
    &::after
        width: 0
        height: 0
        border-left: 15px solid transparent
        border-right: 15px solid transparent
        border-bottom: 20px solid colors.$black
        content: ""
        position: absolute
        right: 25px
        top: -20px
    .profmenu__icon
        width: 15px
        height: 15px
    .profmenu__details
        margin-inline: auto
        display: flex
        flex-flow: row nowrap
        align-items: center
        gap: 15px
        padding: 10px
        border-bottom: 2px solid colors.$white
    .profmenu__initials-container
        background: colors.$white
        cursor: default
        .profmenu__profile-initials
            color: colors.$black
    .profmenu__data-container
        p
            margin-block: 2px
            font-size: fonts.$u-2
            color: colors.$light-gray
            &:first-child
                font-size: fonts.$u-1
    ul > li
        padding: 8px 10px
        font-size: fonts.$u-1
        font-weight: 500
        transition: background ease-in-out 0.2s
        &:hover
            background: colors.$light-gray
            span, .profmenu__icon
                color: colors.$black
        .profmenu__icon
            margin-right: 10px
        &:last-child
            border-radius: 0 0 5px 5px

</style>