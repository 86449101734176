<template>
  <section class="admin">
  <h1>Admin Settings</h1>
  <div class="admin-settings-container">
    <form @submit.prevent="addAdmin">
      <AdminIcon class="icon"/>
      <label for="admin-email">Inserta el email del usuario</label>
      <input type="email" required v-model="adminEmail" name="admin-email" placeholder="newadmin@email.com">
      <p>{{ this.functionMsg }}</p>
      <input type="submit" class="btn" value="Añadir Admin">
    </form>
  </div>
  </section>
</template>

<script>
import firebase from "firebase/app"
import "firebase/functions"
import AdminIcon from "@/assets/Icons/user-crown-light.svg"
export default {
    name: "Admin",
    components: { AdminIcon },
    data() {
      return {
        adminEmail: "",
        functionMsg: null
      }
    },
    methods: {
      async addAdmin() {
        const addAdmin = await firebase.functions().httpsCallable("addAdminRole")
        const result = await addAdmin({email: this.adminEmail})
        this.functionMsg = result.data.message
      }
    },
}
</script>

<style lang="sass" scoped>
@use "../style/fonts"
@use "../style/colors"
section.admin
  padding-block: 60px
  background: colors.$light-gray
  h1
    font-size: fonts.$u3
    font-weight: 300
    text-align: center
    margin-bottom: 10px
.admin-settings-container
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
  padding: 20px
  width: 600px
  margin-inline: auto
  background: colors.$white
  border-radius: 10px
  height: 250px
  .icon
    width: 30px
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2))
  form
    height: 100%
    margin-block: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 5px
    p
      width: 80%
  input[type="email"]
    width: 80%
    height: 40px
    margin-bottom: 10px
    border-radius: 10px
    border: none
    padding-inline: 10px
    font-size: fonts.$u-1
    outline: 2px solid colors.$white
    transition: ease-in-out 0.2s outline
    &:focus-visible
      outline: 2px solid colors.$gray
  label
    text-align: left
    width: 80%
</style>