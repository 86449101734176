import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyBmCLPENxabNqVWokxgQTItMtKJu5ylbAc",
    authDomain: "vue-blog-417b9.firebaseapp.com",
    projectId: "vue-blog-417b9",
    storageBucket: "vue-blog-417b9.appspot.com",
    messagingSenderId: "472030166132",
    appId: "1:472030166132:web:d52de0e29dcfc1f166e521"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();