<template>
  <div class="app-wrapper">
    <div class="app">
      <Navigation v-if="navigation" />
      <div v-if="this.$store.state.postLoaded">
        <router-view class="body-container"/>
        <Footer v-if="navigation" />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import firebase from "firebase/app"
import "firebase/auth"

export default {
  name: "app",
  components: { Navigation, Footer },
  data() {
    return {
      navigation: null
    };
  },

  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit("updateUser", user)
      if(user) {
        this.$store.dispatch("getCurrentUser", user)
      }
    })
    this.checkRoute()
    this.$store.dispatch("getPost")
  },

  mounted() {},
  methods: {
    checkRoute() {
      if (this.$route.name === "Login" || this.$route.name === "Register" || this.$route.name === "ChangePassword") {
        this.navigation = false
        return
      } else {
        this.navigation = true
      }
    }
  },

  watch: {
    $route() {
      this.checkRoute()
    }
  },
};
</script>

<style lang="sass">
@use "./style/links"
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap")

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: "Quicksand", sans-serif

.app
  min-height: 100vh

.container
  max-width: 1440px
  margin: 0 auto
.body-container
  min-height: 63vh
li
  display: block
a
  text-decoration: none
  color: inherit
img
  max-width: 100%
</style>
