<template>
  <div class="background">
    <div class="form-container">
      <p @click="back" class="go-back link link-dark link-underline">&lt; Ir atrás</p>
      <form action="post" @submit.prevent="register">
        <router-link class="link link-dark hover-underline" :to="{ name: 'Login' }">¿Ya tienes una cuenta? Inicia sesión!</router-link>
        <h1>Regístrate en Cinecorder</h1>
        <div class="input">
          <UserIcon class="input-icon"/>
          <input type="text" required placeholder="Nombre" v-model="name">
        </div>
        <div class="input">
          <UserIcon class="input-icon"/>
          <input type="text" required placeholder="Nombre de usuario" v-model="username">
        </div>
        <div class="input">
          <EmailIcon class="input-icon"/>
          <input type="email" required placeholder="E-Mail" v-model="email">
        </div>
        <div class="input">
          <PasswordIcon class="input-icon"/>
          <input type="password" required placeholder="Contraseña" :class="{'input-error': passwordError.length, 'input-success': password.length >= 6}" v-model="password">
          <p class="error">{{ this.passwordError }}</p>
        </div>
        <div class="input">
          <PasswordIcon class="input-icon"/>
          <input type="password" required placeholder="Repite Contraseña" :class="{'input-error': repeatPasswordError.length, 'input-success': repeatPassword === password && repeatPassword.length >= 6}" v-model="repeatPassword">
          <p class="error">{{ this.repeatPasswordError }}</p>
        </div>
        <p class="error error-msg">{{ this.errorMsg }}</p>
        <input class="btn" type="submit" value="Registrarse">
      </form>
    </div>
    <Loader v-if="isLoading"/>
    <UserIllustration class="user-illustration"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue"
import EmailIcon from "@/assets/Icons/envelope-regular.svg"
import PasswordIcon from "@/assets/Icons/lock-alt-solid.svg"
import UserIcon from "@/assets/Icons/user-alt-light.svg"
import UserIllustration from "@/assets/user-illustration.svg"
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";

export default {
  name: "Register",
  components: { UserIllustration, EmailIcon, PasswordIcon, UserIcon, Loader },
  data() {
    return {
      email: "",
      name: "",
      username: "",
      password: "",
      repeatPassword: "",
      repeatPasswordError: "",
      passwordError: "",
      error: null,
      errorMsg: "",
      isLoading: false,
    }
  },
  setup() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    async register() {
      this.passwordError = this.password.length > 5 ?
      "" : "La contraseña debe tener al menos 6 caracteres."
      this.repeatPasswordError = this.password === this.repeatPassword ?
      "" : "La contraseña debe ser la misma."
      if (
        this.email !== "" &&
        this.name !== "" &&
        !this.passwordError &&
        this.username !== "" &&
        !this.repeatPasswordError
      ) {
        this.isLoading = true
        this.error = false
        this.errorMsg = ""
        const firebaseAuth = await firebase.auth();
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
        const result = await createUser;
        const dataBase = db.collection("users").doc(result.user.uid);
        await dataBase.set({
          name: this.name,
          username: this.username,
          email: this.email,
        })
        this.$router.push({ name: "Home" })
        return
      }
      this.error = true
      this.errorMsg = "Por favor, rellena todos los campos correctamente."
      return
    },
  }
  
}
</script>

<style lang="sass" scoped>
@use "../style/_userRegistration"
@use "../style/colors"
@use "../style/fonts"

.form-container
  height: 670px
h1
  font-size: fonts.$u3
  margin-block: 20px
  text-align: center
input::placeholder
      color: colors.$gray
input[type="email"],
input[type="password"],
input[type="text"]
  width: 100%
  height: 40px
  padding-left: 35px
  font-size: fonts.$u-1
  background: colors.$white-shadow
  border: 0
  border-radius: 5px
  outline: 2px auto colors.$white-fff
  transition: outline ease-in-out 0.2s
  &:focus-visible
    outline: 2px auto colors.$gray
input[type="password"].input-error
  outline: 2px auto colors.$red
  color: colors.$red
input[type="password"].input-success
  outline: 2px auto colors.$green
  color: colors.$green
</style>