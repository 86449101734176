import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Posts from "@/views/Posts.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import Profile from "@/views/Profile.vue";
import Admin from "@/views/Admin.vue";
import CreatePost from "@/views/CreatePost.vue";
import EditPost from "@/views/EditPost.vue";
import BlogPreview from "@/views/BlogPreview.vue";
import ViewBlog from "@/views/ViewBlog.vue";
import firebase from "firebase/app"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Inicio',
      requiresAuth: false
    }
  },
  {
    path: "/posts",
    name: "Posts",
    component: Posts,
    meta: {
      title: 'Posts',
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Iniciar sesión',
      requiresAuth: false
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: 'Registrarse',
      requiresAuth: false
    }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: 'Cambiar contraseña',
      requiresAuth: false
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: 'Perfil',
      requiresAuth: true
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: 'Admin',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/create-post",
    name: "CreatePost",
    component: CreatePost,
    meta: {
      title: 'Crear Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/edit-post/:blogid",
    name: "EditPost",
    component: EditPost,
    meta: {
      title: 'Editar Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/blog-preview",
    name: "BlogPreview",
    component: BlogPreview,
    meta: {
      title: 'Previsualizar Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/post/:blogid",
    name: "ViewBlog",
    component: ViewBlog,
    meta: {
      title: 'Post',
      requiresAuth: false
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  let documentTitle = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`
  if (to.params.title) {
    documentTitle = `${to.params.title} - ${process.env.VUE_APP_TITLE}`
  }
  document.title = documentTitle
  next()
})
router.beforeEach(async (to, from, next) => {
  let user = firebase.auth().currentUser
  let admin = null
  if (user) {
    let token = await user.getIdTokenResult()
    admin = token.claims.admin
  }
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next()
        }
        return next({ name: "Home" })
      }
      return next()
    }
    return next({ name: "Home" })
  }
  return next()
})

export default router;