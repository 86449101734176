<template>
  <section class="post-view" v-if="currentBlog">
        <p @click="back" class="go-back link link-dark hover-underline">&lt; Volver atrás</p>
        <div class="container quillWrapper">
            <img class="cover-photo" :src="this.currentBlog[0].blogCoverPhoto" :alt="this.currentBlog[0].blogTitle">
            <p class="post-date">{{ new Date(this.currentBlog[0].blogDate).toLocaleString("es-ar", { dateStyle: "long" }) }}</p>
            <h3 class="post-tags">{{ this.currentBlog[0].blogTag }}</h3>
            <h1 class="post-title">{{ this.currentBlog[0].blogTitle }}</h1>
            <div class="post-content ql-editor" v-html="this.currentBlog[0].blogHTML">

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ViewBlog",
    data() {
      return {
        currentBlog: null,
      }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    },
    async mounted() {
      this.currentBlog = await this.$store.state.blogPosts.filter((post) => {
        return post.blogID === this.$route.params.blogid
      })
    }
}
</script>

<style lang="sass">

</style>