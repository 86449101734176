import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/app"
import "firebase/auth"
import db from "../firebase/firebaseInit"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    blogPosts: [],
    postLoaded: null,
    blogHTML: "Escriba el título de su blog aquí...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    blogDescription: null,
    blogTag: null,
    editPosts: null,
    user: null,
    profileEmail: null,
    profileName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
    profileAdmin: null
  },
  getters: {
    blogPostsFeed(state) {
      return state.blogPosts.slice(0, 2)
    },
    blogPostsCards(state) {
      return state.blogPosts.slice(2, 6)
    }
  },
  mutations: {
    toggleEditPosts(state, payload) {
      state.editPosts = payload
    },
    // Recreates array of posts by filtering every single post except the deleted one
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter(post => post.blogID !== payload)
    },
    setBlogState(state, payload) {
      (state.blogTitle = payload.blogTitle);
      (state.blogHTML = payload.blogHTML);
      (state.blogPhotoFileURL = payload.blogCoverPhoto);
      (state.blogPhotoName = payload.blogCoverPhotoName);
      (state.blogDescription = payload.blogDescription);
      (state.blogTag = payload.blogTag)
    },
    updateUser(state, payload) {
      state.user = payload
    },
    setProfileInfo(state, payload) {
      state.profileId = payload.id
      state.profileEmail = payload.data().email
      state.profileName = payload.data().name
      state.profileUsername = payload.data().username
    },
    setProfileInitials(state) {
      state.profileInitials =
      state.profileName.charAt(0);
    },
    changeName(state, payload) {
      state.profileName = payload
    },
    changeUsername(state, payload) {
      state.profileUsername = payload
    },
    setProfileAdmin(state, payload) {
      state.profileAdmin = payload
    },
    newBlogPost(state, payload) {
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload;
    },
    updateBlogDescription(state, payload) {
      state.blogDescription = payload;
    },
    updateBlogTag(state, payload) {
      state.blogTag = payload;
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload;
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload;
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },
  },
  actions: {
    async getCurrentUser({commit}, user) {
      const dataBase = await db.collection("users").doc(firebase.auth().currentUser.uid)
      const dbResults = await dataBase.get()
      commit("setProfileInfo", dbResults)
      commit("setProfileInitials")
      const token = await user.getIdTokenResult()
      const admin = await token.claims.admin
      commit("setProfileAdmin", admin)
    },
    async updateUserSettings({commit, state}) {
      const dataBase = await db.collection("users").doc(state.profileId)
      await dataBase.update({
        name: state.profileName,
        username: state.profileUsername
      })
      commit("setProfileInitials")
    },
    async getPost({ state }) {
      const dataBase = await db.collection("blogPosts").orderBy("date", "desc")
      const dbResults = await dataBase.get()
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some(post => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogDescription: doc.data().blogDescription,
            blogTag: doc.data().blogTag
          }
          state.blogPosts.push(data)
        }
      });
      state.postLoaded = true
    },
    async deletePost({commit}, payload) {
      const getPost = await db.collection("blogPosts").doc(payload)
      await getPost.delete()
      commit("filterBlogPost", payload)
    },
    async updatePost({commit, dispatch}, payload) {
      commit("filterBlogPost", payload)
      await dispatch("getPost")
    },
  },
  modules: {
  }
})

