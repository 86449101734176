<template>
  <router-link :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID, title: this.post.blogTitle }}">
    <article class="card-container">
      <div class="icons" v-show="editPosts">
        <div class="icon" @click="editPost">
          <Edit class="icon-edit"/>
        </div>
        <div class="icon" @click="deletePost">
          <Delete class="icon-delete"/>
        </div>
      </div>
      <img loading="lazy" :src="post.blogCoverPhoto" :alt="post.blogTitle">
      <div class="post-info">
        <h6>Posteado en: {{ new Date(post.blogDate).toLocaleString("es-ar", { dateStyle: "long" }) }}</h6>
        <h4>{{ post.blogTitle }} </h4>
        <p class="link link-dark hover-underline">Leer más <Arrow class="arrow" /></p>
      </div>
    </article>
  </router-link>
</template>

<script>
import Edit from "@/assets/Icons/edit-regular.svg"
import Delete from "@/assets/Icons/trash-regular.svg"
import Arrow from "@/assets/Icons/arrow-right-light.svg"
export default {
  name: "BlogCard",
  props: ["post", "editPosts"],
  components: {
    Edit,
    Delete,
    Arrow
  },
  methods: {
    deletePost() {
      this.$store.dispatch("deletePost", this.post.blogID)
    },
    editPost() {
      this.$router.push({ name: "EditPost", params: {blogid: this.post.blogID} })
    }
  },
}
</script>

<style lang="sass" scoped>
@use "../style/colors"
@use "../style/fonts"

article.card-container
  height: 420px
  width: 320px
  position: relative
  border-radius: 10px
  transition: 0.5s all ease-in-out
  background: colors.$white-fff
  @media (max-width: 690px)
    margin: auto
    width: 80%
  &:hover
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
    transform: rotate(-1deg)
    .link
      border-bottom: 2px solid colors.$black
.icons
  position: absolute
  top: 5px
  right: 5px
  display: flex
  flex-flow: row nowrap
  .icon
    display: flex
    justify-content: center
    align-items: center
    height: 40px
    width: 40px
    border-radius: 50%
    background: colors.$white
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px
    margin-inline: 5px
    cursor: pointer
    transition: 0.3s ease-in-out
    &:first-child:hover
      background: colors.$black
      .icon-edit
        color: colors.$white
    &:last-child:hover
      background: colors.$red
      .icon-delete
        color: colors.$white
img
  width: 100%
  height: 180px
  background: colors.$light-gray
  object-fit: cover
  border-radius: 10px 10px 0 0
.post-info
  display: flex
  flex-flow: column nowrap
  width: 90%
  margin: auto
  h4
    font-size: fonts.$u1
    font-weight: 200
    margin-top: 10px
    line-height: 1.1em
  h6
    font-weight: 700
    font-size: fonts.$u-2
  .link
    position: absolute
    bottom: 15px
    right: 15px

    .arrow
      height: 15px

</style>