<template>
  <section class="recent-posts">
        <div class="container">
          <div v-if="admin" class="toggle-edit-container">
            <label for="toggle-edition-mode">Toggle Edition of Post</label>
            <label class="switch">
              <input type="checkbox" name="toggle-edition-mode" v-model="editPosts">
              <span class="slider"></span>
            </label>
          </div>

          <h2>Read the Latest Posts</h2>
          <div class="cards-container">
            <BlogCard :editPosts="editPosts" :post="post" v-for="(post, index) in blogPosts" :key="index" />
          </div>
        </div>
  </section>
</template>

<script>
import BlogCard from "../components/BlogCard.vue"

export default {
  name: "Posts",
  components: {BlogCard},
  computed: {
    admin() {
      return this.$store.state.profileAdmin
    },
    blogPosts() {
      return this.$store.state.blogPosts
    },
    editPosts: {
      get() {
        return this.$store.state.editPosts
      },
      set(payload) {
        this.$store.commit("toggleEditPosts", payload)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@use "@/style/__blogCardsSection"
@use "../style/colors"
@use "../style/fonts"
section.recent-posts
  background: colors.$white-shadow
.toggle-edit-container
  display: flex
  align-items: center
  gap: 5px
  justify-content: flex-end
  .switch
    font-size: fonts.$u-2
    position: relative
    display: inline-block
    width: 3.5em
    height: 2em

  .switch input
    opacity: 0
    width: 0
    height: 0

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: colors.$gray
    transition: .4s
    border-radius: 30px

  .slider:before
    position: absolute
    content: ""
    height: 1.4em
    width: 1.4em
    border-radius: 20px
    left: 0.3em
    bottom: 0.3em
    background-color: white
    transition: .4s

  input:checked + .slider
    background-color: colors.$green
  input:focus + .slider
    box-shadow: 0 0 2px colors.$gray
  input:checked + .slider:before
    transform: translateX(1.5em)
</style>