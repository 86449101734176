<template>
  <div class="background">
    <Modal v-if="displayModal" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
    <Loader v-if="isLoading" />
    <div class="form-container">
      <p @click="back" class="go-back link link-dark link-underline">&lt; Go Back</p>
      <form action="post" @submit.prevent="resetPassword">
        <h1>Reset your Password</h1>
        <p class="reset-description">If your Email is correct, we will send you a message to change your password</p>
        <div class="input">
          <EmailIcon class="input-icon"/>
          <input type="email" required placeholder="E-Mail" v-model="email">
        </div>
        <input class="btn" type="submit" value="Send email" >
      </form>
    </div>
    <UserIllustration class="user-illustration"/>
  </div>
</template>

<script>
import EmailIcon from "@/assets/Icons/envelope-regular.svg"
import UserIllustration from "@/assets/user-illustration.svg"
import Modal from "@/components/Modal.vue"
import Loader from "@/components/Loader.vue"
import firebase from "firebase/app"
import "firebase/auth"
export default {
  name: "ChangePassword",
  components: { UserIllustration, EmailIcon, Modal, Loader },
  data() {
    return {
      isLoading: false,
      email: "",
      displayModal: false,
      modalMessage: "",
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    closeModal() {
      this.displayModal = false
    },
    openModal() {
      this.displayModal = true
    },
    resetPassword() {
      this.loading = true
      firebase
      .auth().
      sendPasswordResetEmail(this.email)
      .then(() => {
        this.modalMessage = "Check your email for a message to change your Password."
        this.loading = false
        this.displayModal = true
      }).catch((err) => {
        this.modalMessage = err.message
        this.loading = false
        this.displayModal = true
      })
    }
  },
  
}
</script>

<style lang="sass" scoped>
@use "../style/_userRegistration"
@use "../style/colors"
@use "../style/fonts"
h1
  font-size: fonts.$u3
  margin-bottom: 5px
  text-align: center
.reset-description
  text-align: center
  margin-bottom: 20px
input::placeholder
      color: colors.$gray
input[type="email"]
  width: 100%
  height: 40px
  padding-left: 35px
  font-size: fonts.$u-1
  background: colors.$white-shadow
  border: 0
  border-radius: 5px
  outline: 2px auto colors.$white-fff
  transition: outline ease-in-out 0.2s
  &:focus-visible
    outline: 2px auto colors.$gray
</style>