<template>
  <div class="loader-background">
      <span></span>
  </div>
</template>

<script>
export default {
    name: "Loader",
}
</script>

<style lang="sass" scoped>
@use "../style/colors"

.loader-background
    position: absolute
    top: 0
    width: 100%
    height: 100%
    background: rgba(23, 33, 38, 0.8)
    z-index: 99
    display: flex
    align-items: center
    justify-content: center
    @keyframes spin
        to
            transform: rotateZ(360deg)
    span
        display: block
        width: 60px
        height: 60px
        margin-inline: auto
        border: 3px solid transparent
        border-top-color: colors.$white
        border-bottom-color: colors.$white
        border-radius: 50%
        animation: spin ease 1000ms infinite
</style>