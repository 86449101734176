<template>
  <section class="profile">
    <Modal v-show="showModal" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
    <div class="profile-container">
      <h1>Profile Settings</h1>
      <div class="profile-settings-container">
        <div class="profile-initials-container">
          <span>{{ this.$store.state.profileInitials }}</span>
        </div>
        <router-link :to="{name: 'Admin'}" class="admin-button btn">
          <AdminIcon class="icon"/>
          <span> Admin</span>
        </router-link>
        <form @submit.prevent="updateProfile">
          <label for="name">Nombre</label>
          <input type="text" name="name" v-model="name" placeholder="Tu nombre">

          <label for="username">Nombre de usuario</label>
          <input type="text" name="username" v-model="username"  placeholder="Tu nombre de usuario">

          <label for="email">Email</label>
          <input disabled type="text" name="email" :value="this.$store.state.profileEmail" placeholder="Tu email">
          <p class="error" v-if="error">{{errorMsg}}</p>
          <input type="submit" class="btn" value="Guardar cambios">
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import AdminIcon from "@/assets/Icons/user-crown-light.svg"
import Modal from "@/components/Modal.vue"
export default {
    name: "Profile",
    components: { AdminIcon, Modal },
    data() {
      return {
        modalMessage: "Los cambios en tu perfil han sido guardados!",
        showModal: false,
        error: false,
        errorMsg: ""
      }
    },
    computed: {
      name: {
        get() {
        return this.$store.state.profileName
        },
        set(payload) {
          this.$store.commit("changeName", payload)
        }
      },
      username: {
        get() {
        return this.$store.state.profileUsername
        },
        set(payload) {
          this.$store.commit("changeUsername", payload)
        }
      }
    },
    methods: {
      updateProfile() {
        if(this.username !== "" && this.name !== "") {
          this.error = false
          this.$store.dispatch("updateUserSettings")
          this.showModal = !this.showModal
        } else {
          this.error = true
          this.errorMsg = "Llena correctamente los campos de tu nombre y nombre de usuario"
        }
        
      },
      closeModal() {
        this.showModal = !this.showModal
      }
    },
}
</script>

<style lang="sass" scoped>
@use "../style/fonts"
@use "../style/colors"
section.profile
    .profile-container
      padding-block: 60px
      background: colors.$light-gray
      h1
        font-size: fonts.$u3
        font-weight: 300
        text-align: center
        margin-bottom: 10px
    svg
      width: 15px
.profile-settings-container
  padding: 20px
  width: 600px
  margin-inline: auto
  background: colors.$white
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around
  gap: 10px
  border-radius: 30px
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
  .profile-initials-container
    display: flex
    justify-content: center
    align-items: center
    background: colors.$black
    $size: 60px
    width: $size
    height: $size
    border-radius: $size
    span
        text-transform: uppercase
        font-size: fonts.$u1
        font-weight: 600
        color: colors.$white
  form
    margin-block: 30px
    display: flex
    flex-direction: column
    align-items: center
    width: 70%
    input[type="text"],
    input[type="email"]
      width: 80%
      height: 40px
      margin-bottom: 20px
      border-radius: 10px
      border: none
      padding-inline: 10px
      font-size: fonts.$u-1
      outline: 2px solid colors.$white
      transition: ease-in-out 0.2s outline
      &:disabled
        background: colors.$light-gray
        cursor: no-drop
      &:focus-visible
        outline: 2px solid colors.$gray
    label
      text-align: left
      width: 80%
    .error
      margin-bottom: 10px
</style>